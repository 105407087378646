import { useNavigate } from '@remix-run/react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type FuncNavigate = (urlToNavigateTo?: string) => void;

type FormSaveAndNavigateContextType = [
  FuncNavigate,
  (saveForm: FuncNavigate | null) => void,
];

export const FormSaveAndNavigate =
  createContext<FormSaveAndNavigateContextType | null>(null);

export function useSaveFormAndNavigate() {
  const value = useContext(FormSaveAndNavigate);

  if (!value) {
    throw new Error(
      'useSaveForm must be used within a FormSaveAndNavigateProvider',
    );
  }

  return value;
}

export function FormSaveAndNavigateProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const navigate = useNavigate();
  const handleNavigate = useCallback(
    (navigateTo?: string) => {
      if (!navigateTo) {
        return;
      }

      navigate(navigateTo);
    },
    [navigate],
  );
  const [saveForm, setSaveForm] = useState<FuncNavigate>(handleNavigate);

  const value = useMemo(
    () =>
      [
        saveForm,
        (saveForm: null | FuncNavigate) => {
          setSaveForm(() => {
            if (saveForm) return saveForm;

            return handleNavigate;
          });
        },
      ] as FormSaveAndNavigateContextType,
    [handleNavigate, saveForm],
  );

  return (
    <FormSaveAndNavigate.Provider value={value}>
      {children}
    </FormSaveAndNavigate.Provider>
  );
}
